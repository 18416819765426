import womanPlayingVolley from '../../assets/images/main-banner.webp';
import gradientImg from '../../assets/images/gradient.png';
import gradientSecondaryImg from '../../assets/images/gradient-secondary.png';
import Button from '../../components/Button/Button';
import Navbar from '../../components/Navbar/Navbar';
import { useWindowWidth } from '../../hooks/WindowSizeContext';
import styles from './index.module.css';
import { ArenaCard, DifferencialCard, RecordCard } from './components';
import playButton from '../../assets/images/play-button.svg';
import arrowRight from '../../assets/images/arrow-right.svg';
import cameraImg from '../../assets/images/camera.png';
import sagradaBeach from '../../assets/images/sagrada-beach.webp';
import recreativo from '../../assets/images/recreativo.webp';
import caraiva from '../../assets/images/caraiva.webp';
import dunaSports from '../../assets/images/duna-sports.webp';
import penaareia from '../../assets/images/penaareia.webp';
import sport from '../../assets/images/sport.webp';
import vila from '../../assets/images/vila.webp';
import renascenca from '../../assets/images/renascenca.webp';
import g2 from '../../assets/images/g2.webp';
import replay from '../../assets/images/replay.webp';
import lagoa from '../../assets/images/lagoa.webp';
import { handleContractCameraWhatsapp } from '../../utils/index';

const videosCards = [
  {
    title: 'Pe na Areia Bar e Clube',
    description: ' Av. João Antunes de Oliveira, n° 7 - Campo Belo, Diamantina - MG, 39100-000',
    url: '?state=mg&city=belohorizonte&arena=Pé Na Areia Esporte Bar',
    imgPath: penaareia
  },
  {
    title: 'Villammare',
    description: 'Av. Santa Rosa, 796 - São Luiz, Belo Horizonte - MG, 31741-095',
    url: '?state=mg&city=belohorizonte&arena=Villa Mmaré',
    imgPath: vila
  },
  {
    title: 'G2',
    description: 'Rua Nicolina Pacheco, 298 - Palmares, Belo Horizonte, Brazil',
    url: '?state=mg&city=belohorizonte&arena=G2',
    imgPath: g2
  },
  {
    title: 'Renascença',
    description: 'Av. Bernardo de Vasconcelos, 1556 - Renascença, Belo Horizonte - MG, 31150-000',
    url: '?state=mg&city=belohorizonte&arena=Renascenca',
    imgPath: renascenca
  },
  {
    title: 'Lagoa Beach',
    description: 'Av. Alfredo Camarate, 190 - São Luiz, Belo Horizonte - MG, 31310-000',
    url: '?state=mg&city=belohorizonte&arena=Lagoa Beach',
    imgPath: lagoa
  },
  {
    title: 'Replay',
    description: 'R. Paulista, 1333 - Fernão Dias, Belo Horizonte - MG, 31910-340',
    url: '?state=mg&city=belohorizonte&arena=Replay',
    imgPath: replay
  },
  {
    title: 'Caraíva Beach',
    description: 'R. Peperi, 740 - Nova Granada, Belo Horizonte - MG, 30431-340',
    url: '?state=mg&city=belohorizonte&arena=Caraiva',
    imgPath: caraiva
  },
  {
    title: 'Sport Beach BH',
    description: 'Rua Javari, 620 - Renascença, Belo Horizonte - MG, 31130-302',
    url: '?state=mg&city=belohorizonte&arena=Sport%20Beach%20BH',
    imgPath: sport
  },
  {
    title: 'Duna Sports',
    description: 'R. Ilacir Pereira Lima, 55 - Silveira, Belo Horizonte - MG, 31140-540',
    url: '?state=mg&city=belohorizonte&arena=Duna',
    imgPath: dunaSports
  },
  {
    title: 'Recreativo',
    description: '197 Sion, R. Grão Mogol, 197 - Carmo, Belo Horizonte - MG, 30310-010',
    url: '?state=mg&city=belohorizonte&arena=Recreativo',
    imgPath: recreativo
  },
  {
    title: 'Sagrada Beach',
    description: 'R. Itacoatiara, 343 - Sagrada Família, Belo Horizonte - MG, 31035-400',
    url: '?state=mg&city=belohorizonte&arena=Sagrada Beach',
    imgPath: sagradaBeach
  }
];

function Home() {
  const { windowWidth } = useWindowWidth();

  const MobileHome = () => (
    <>
      <Navbar menuTextColor="transparent" />
      <div className={`${styles.main_container}`}>
        <main className={`${styles.container}`}>
          <div className={`${styles.watch_videos_text_container}`}>
            <h1 className={styles.high_quality_video_text}>Seus vídeos em alta qualidade</h1>
            <p className={styles.your_plays_text}>assim como suas jogadas</p>
            <Button
              text="Ver meus vídeos"
              href="/videos"
              backgroundColor="white"
              icon={playButton}
            />
          </div>
        </main>
        <div className={styles.videos_container}>
          <div className={styles.videos_wrapper}>
            {videosCards.map((video, index) => (
              <ArenaCard
                key={video.title + index}
                title={video.title}
                description={video.description}
                url={video.url}
                imgPath={video.imgPath}
              />
            ))}
          </div>
        </div>
        <div className={styles.texts_wrapper}>
          <h2>Simples e eficaz</h2>
          <p>
            Nosso kit é fácil de instalar, podendo ser montado por qualquer eletricista ou faz-tudo.
            Com o auxílio de nosso tutorial em vídeo, o processo é descomplicado.
          </p>
          <h2>À prova d’agua</h2>
          <p>
            A câmera é à prova d’água e sol, garantindo segurança e gravação em Full HD com captura
            de áudio de alta qualidade.
          </p>
          <Button
            backgroundColor="white"
            text="Saiba mais"
            icon={arrowRight}
            onClick={handleContractCameraWhatsapp}
          />
          <img
            src={gradientImg}
            className={styles.gradient}
            alt="Mancha para design com cor laranja"
          />
        </div>
        <div className={styles.differencial_container}>
          <DifferencialCard />
          <img
            src={gradientSecondaryImg}
            className={styles.gradient_secondary}
            alt="Mancha para design com cor laranja"
          />
        </div>
        <div className={styles.record_container}>
          <RecordCard />
        </div>
      </div>
    </>
  );

  const DesktopHome = () => (
    <>
      <Navbar menuTextColor="transparent" />
      <div className={`${styles.main_container}`}>
        <main className={`${styles.container}`}>
          <div className={`${styles.watch_videos_text_container}`}>
            <h1 className={styles.high_quality_video_text}>Seus vídeos em alta qualidade</h1>
            <p className={styles.your_plays_text}>assim como suas jogadas</p>
            <Button
              text="Ver meus vídeos"
              href="/videos"
              backgroundColor="white"
              icon={playButton}
            />
          </div>
          <img
            src={womanPlayingVolley}
            className={styles.woman_playing_volleyball_img}
            alt="Mulher jogando volei com um símbolo de play no meio da imagem"
          />
        </main>
        <div className={styles.videos_container}>
          <div className={styles.videos_wrapper}>
            {videosCards.map((video, index) => (
              <ArenaCard
                key={video.title + index}
                title={video.title}
                description={video.description}
                url={video.url}
                imgPath={video.imgPath}
              />
            ))}
          </div>
        </div>
        <div className={styles.texts_container}>
          <div className={styles.texts_wrapper}>
            <h2>Simples e eficaz</h2>
            <p>
              Nosso kit é fácil de instalar, podendo ser montado por qualquer eletricista ou
              faz-tudo. Com o auxílio de nosso tutorial em vídeo, o processo é descomplicado.
            </p>
            <h2>À prova d’agua</h2>
            <p>
              A câmera é à prova d’água e sol, garantindo segurança e gravação em Full HD com
              captura de áudio de alta qualidade.
            </p>
            <Button
              backgroundColor="white"
              text="Saiba mais"
              icon={arrowRight}
              className={styles.know_more_button}
              onClick={handleContractCameraWhatsapp}
            />
          </div>
          <img
            src={cameraImg}
            className={styles.differencial_card_image}
            alt="Camêra redonda branca para gravação"
          />
          <img
            src={gradientImg}
            className={styles.gradient}
            alt="Mancha para design com cor laranja"
          />
        </div>
        <div className={styles.differencial_container}>
          <DifferencialCard />
          <img
            src={gradientSecondaryImg}
            className={styles.gradient_secondary}
            alt="Mancha para design com cor laranja"
          />
        </div>
        <div className={styles.record_container}>
          <RecordCard />
        </div>
      </div>
    </>
  );

  return windowWidth >= 768 ? <DesktopHome /> : <MobileHome />;
}

export default Home;
