import { useContext, useEffect, useState } from 'react';
import styles from './index.module.css';
import sharkAttackImg from '../../../../assets/images/shark-attack-smartphone.webp';
import SearchButton from '../SearchButton';
import { useWindowWidth } from '../../../../hooks/WindowSizeContext';
import SearchDropdown from '../SearchDropdown';
import { convertToArenaOptions, convertToCourtOptions } from '../../../../utils/index';
import { SearchContext } from '../..';
import { useLocation } from 'react-router-dom';

interface SearchFormProps {
  dataArena: any;
  onSearch: () => void;
}

const optionsCity = [
  { id: 'belohorizonte', value: 'Belo Horizonte' },
  { id: 'diamantina', value: 'Diamantina' }
];
const optionsState = [{ id: 'mg', value: 'MG' }];

function SearchForm({ onSearch, dataArena }: SearchFormProps) {
  const location = useLocation();
  const { windowWidth } = useWindowWidth();
  const { city, setCity, state, setState, arena, setArena, court, setCourt, date, setDate } =
    useContext<any>(SearchContext);

  const [arenaOptions, setArenaOptions] = useState<any>();
  const [courtOptions, setCourtOptions] = useState();

  useEffect(() => {
    if (dataArena) {
      setArenaOptions(convertToArenaOptions(dataArena));
    }
  }, [dataArena]);

  useEffect(() => {
    if (arena) {
      setCourtOptions(convertToCourtOptions(arena));
    }
  }, [arena]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const cityParam = searchParams.get('city');
    const stateParam = searchParams.get('state');
    const arenaParam = searchParams.get('arena');

    if (cityParam) {
      const matchedCity = optionsCity.find((option) => option.id === cityParam);
      setCity(matchedCity);
    }

    if (stateParam) {
      const matchedState = optionsState.find((option) => option.id === stateParam);
      setState(matchedState);
    }

    if (arenaParam && arenaOptions) {
      const matchedArena = arenaOptions.find((option: any) => option.value === arenaParam);
      setArena(matchedArena);
    }
  }, [location.search, arenaOptions]);

  const isDisabled = city && state && arena && court && date === '';

  return (
    <div className={styles.search_form_container}>
      {windowWidth > 768 && (
        <img
          src={sharkAttackImg}
          className={styles.search_image}
          alt="Mão segurando um celular que exibe uma partida de futevôlei na tela."
        />
      )}
      <div className={styles.search_form_wrapper}>
        <h2>Seus Vídeos</h2>
        <div className={styles.form}>
          <div className={styles.form_wrapper}>
            <div className={styles.form_group}>
              <label htmlFor="court">Cidade</label>
              <SearchDropdown
                selectedOption={city}
                onSelect={setCity}
                options={optionsCity}
                placeholder="Cidade"
              />
            </div>
            <div className={styles.form_group}>
              <label htmlFor="court">Estado</label>
              <SearchDropdown
                selectedOption={state}
                onSelect={setState}
                options={optionsState}
                placeholder="Estado"
              />
            </div>
          </div>
          <div className={styles.form_wrapper}>
            <div className={styles.form_group}>
              <label htmlFor="court">Arena</label>
              <SearchDropdown
                selectedOption={arena}
                onSelect={setArena}
                options={arenaOptions}
                placeholder="Arena"
              />
            </div>
            <div className={styles.form_group}>
              <label htmlFor="court">Quadra</label>
              <SearchDropdown
                selectedOption={court}
                onSelect={setCourt}
                options={courtOptions}
                placeholder="Quadra"
              />
            </div>
          </div>
          <div className={styles.form_group}>
            <label htmlFor="date">Data</label>
            <input
              id="date"
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              placeholder="DD/MM/AAAA"
            />
          </div>
        </div>
        <div className={styles.button_group}>
          <SearchButton onClick={onSearch} isBlack isDisabled={isDisabled} />
        </div>
      </div>
    </div>
  );
}

export default SearchForm;
